import React from "react";
import styled from "styled-components";
import BasicLink from "@common/links/BasicLink";
import Button from "@common/buttons/Button";

const Hero = styled.div`
  background: ${(props) => props.theme.gradients.lightBlue};
  width: 100%;
  min-height: 195px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  @media (max-width: ${(props) => props.theme.breakPoints.md}) {
    min-height: 100px;
    padding: 20px 0px 35px;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.xs}) {
    padding: 0px 0px 14px;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.xxs}) {
    min-height: 115px;
  }
`;

const HeroInner = styled.div`
  max-width: 650px;
  margin: auto;
  padding: 0px 20px;
  & h1 {
    margin: 0px;
    font-family: ${(props) => props.theme.fonts.headline};
    font-weight: 800;
    text-transform: uppercase;
    color: ${(props) => props.theme.colors.darkPrimary};
    font-size: 5rem;
    margin-bottom: 0px;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.md}) {
    & h1 {
      font-size: 4.5rem;
      margin-bottom: 0px;
    }
  }
  @media (max-width: ${(props) => props.theme.breakPoints.xs}) {
    & h1 {
      font-size: 3.1rem;
      margin-bottom: 10px;
    }
  }
  @media (max-width: ${(props) => props.theme.breakPoints.xxs}) {
    & h1 {
      font-size: 2.8rem;
      line-height: 30px;
    }
  }
`;

const AuthFormLayoutContainer = styled.div`
  display: flex;
  background-size: cover;
  justify-content: center;
  background-position: center;
  align-items: center;
  padding-bottom: 80px;
  & h3 {
    font-size: 29px;
    letter-spacing: 1px;
  }
  @media (max-width: ${(props) => props.theme.breakPoints.xs}) {
    min-height: auto;
    padding: 0px 30px;
    padding-bottom: 30px;
  }
`;

const AuthFormContainer = styled.div`
  background: white;
  border-radius: ${(props) => props.theme.borderRadius};
  box-shadow: ${(props) => props.theme.boxShadow.blurred};
  max-width: 300px;
  min-width: 400px;
  padding: 35px 20px 30px;
  margin-top: -40px;
  z-index: 99;
  @media (max-width: ${(props) => props.theme.breakPoints.xs}) {
    min-width: 100%;
    padding: 25px 15px 30px;
  }
`;

const LegalText = styled.p`
  text-align: center;
  line-height: 28px;
  padding: 0px 0px;
  font-size: 1.45rem;
  margin: 0px;
  margin-top: 10px;
  color: gray;
  a {
    color: gray;
    text-decoration: underline;
  }
`;

const CallOutText = styled.p`
  margin: 0px;
  color: ${(props) => props.theme.colors.darkPrimary};
  font-size: 1.95rem;
  margin-bottom: 14px;
  margin-top: -13px;
  font-weight: 600;
  text-align: center;
  line-height: 26px;
  padding: 0px 10px;
  @media (max-width: ${(props) => props.theme.breakPoints.xs}) {
    padding: 0px;
    font-size: 1.7rem;
    line-height: 23px;
  }
`;

const RegisterAccountPrompt = styled.div`
  margin-top: 15px;
  height: fit-content;
  background: ${(props) => props.theme.colors.extraLightPrimary};

  border-radius: 6px;
  cursor: pointer;
  padding: 20px 10px;
  transition: ${(props) => props.theme.transitions.standard};
  opacity: 1;
  text-align: center;

  :hover {
    transition: ${(props) => props.theme.transitions.standard};
    opacity: 0.8;
  }
`;

const RegisterAccountInformationContainer = styled.div`
  text-align: center;
  & h3 {
    color: ${(props) => props.theme.colors.primary};
    font-weight: 500;
    margin: 0px 0px 5px 0px;
    font-size: 2.14rem;
    letter-spacing: 0.2px;
    max-width: 240px;
    line-height: 29px;
    margin: 0px auto 10px;
  }
`;

// Simple layout that contains the login form adn forgot password forms
const AuthFormLayout = (props) => (
  <>
    <Hero>
      <HeroInner>
        <h1>{props.title.toUpperCase()}</h1>
      </HeroInner>
    </Hero>
    <AuthFormLayoutContainer>
      <AuthFormContainer>
        {props.showResetPassword && (
          <CallOutText>
            Enter your email address so we can email you a link to reset your
            password.
          </CallOutText>
        )}
        {props.showRequestAccountInformation && (
          <CallOutText>
            Provide the email you used in checkout in order to complete your
            account setup. Once complete, you can manage your subscriptions
            directly within your account.
          </CallOutText>
        )}
        {props.children}
        {props.showLegal && (
          <>
            <LegalText>
              By logging into your account, you accept the{" "}
              <BasicLink href="/legal/terms-and-conditions">
                Terms & Conditions
              </BasicLink>{" "}
              and{" "}
              <BasicLink href="/legal/privacy-policy">Privacy Policy</BasicLink>
              . Update effective as of 12/31/19.
            </LegalText>
            <BasicLink href={`/register`}>
              <RegisterAccountPrompt>
                <RegisterAccountInformationContainer>
                  <h3>Need help accessing your subscriptions?</h3>
                  <Button modifiers={["primary", "mini"]}>Register Here</Button>
                </RegisterAccountInformationContainer>
              </RegisterAccountPrompt>
            </BasicLink>
          </>
        )}
        {props.showResetPassword && (
          <LegalText>
            <BasicLink href="/login">Nevermind, go back to login</BasicLink>
          </LegalText>
        )}
        {(props.showRegisterInformation ||
          props.showRequestAccountInformation) && (
          <LegalText>
            <BasicLink href="/login">
              Already have an account? Sign In Here.
            </BasicLink>
          </LegalText>
        )}
      </AuthFormContainer>
    </AuthFormLayoutContainer>
  </>
);

export default AuthFormLayout;
