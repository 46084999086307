import { useEffect } from 'react';
import { NextSeo } from 'next-seo';

import AuthFormLayout from '@containers/auth/AuthFormLayout';
import { toast } from 'react-toastify';
import FallBackLoader from "@components/common/loaders/FallBackLoader";

import { useRecoilValue } from 'recoil'
import { userState } from '@atoms/user'
import { requestMultiPassLink } from "@helpers/auth";

import Router from 'next/router';
import LoginForm from '@components/forms/auth/LoginForm';


function Login({ error, next, checkout}) {
    const user = useRecoilValue(userState)

    useEffect(() => {
        (async function() {
          if (user && !user.loading) {
            if (checkout) {
                if (localStorage.getItem("accessToken")) {
                    let redirectURL = await requestMultiPassLink(next)
                    window.location.href =  redirectURL;
                } else {
                    window.location.href =  next;
                }
            } else if (next) {
                Router.push(next)
            } else {
                Router.push("/profile/your-next-box")
            }
        }
        })();


    }, [user]);

    useEffect(() => {
        if (error) {
            toast.error("You need login before continuing")
            Router.push(`/login?next=${next}`)
        }
    }, []);

    return (
        <>
            <NextSeo 
                title="Login"
                openGraph={{
                    url: 'https://www.cleancult.com/login/',
                    title: 'Login | Cleancult',
                }}
            />
            {
                (user && user.loading) ? (
                    <FallBackLoader />
                ) : (
                    <>
                    {
                        !user ? (
                            <AuthFormLayout title="Welcome Back" showLegal={true}>
                                <LoginForm nextURL={next}/>
                            </AuthFormLayout>
                        ) : (
                            <FallBackLoader /> 
                        )
                    }
                    </>
                )
            }
        </>
    )
}

// Check to see if the user is already authenticated. If so... direct them to the profile page
Login.getInitialProps = async ({ query }) => {
    // If there is an redirect error send out a toas on componment did mount
    const { error, next, checkout } = query;
        
    return {
        error: error,
        next: next,
        checkout: checkout
    }
};

export default Login;