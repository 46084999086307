import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinnerThird } from "@fortawesome/pro-light-svg-icons";
import styled from "styled-components";

const FallBackLoaderContainer = styled.div`
    text-align: center;
    padding-top: 60px;
    & svg {
        font-size: 7rem;
        color: ${props => props.theme.colors.darkPrimary};
    }
`

// Used to pass through the "active" modifier fir the router asPath == the current URL
const FallBackLoader = () => {
    return (
        <FallBackLoaderContainer>
            <FontAwesomeIcon icon={faSpinnerThird}  spin/>
        </FallBackLoaderContainer>
    )
};

export default FallBackLoader;