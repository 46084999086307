import React from "react";
import { Form, Field } from "react-final-form";
import Button from "@common/buttons/Button";
import { emailIsValid } from "@helpers";
import { PlainTextField } from "@fields";
import styled from "styled-components";
import BasicLink from "@common/links/BasicLink";
import { FormCol } from "@forms/FormStyles";
import EmailCheck from "@forms/helpers/EmailCheck";
import { toast } from "react-toastify";
import { userState } from "@atoms/user";
import { useSetRecoilState } from "recoil";
import { loginUser } from "@helpers/auth";
import { AnalyticsLogin } from "@helpers/analytics";

const LoginFormFieldSet = styled.fieldset`
  a {
    float: right;
    margin-bottom: 10px;
    margin-top: -5px;
    font-weight: 500;
    font-size: 14px;
    color: ${(props) => props.theme.colors.primary};
  }
`;

// User authentication form
function LoginForm() {
  const setUser = useSetRecoilState(userState);

  // Submit the email and password
  const handleLogin = async (formValues) => {
    // Sign in and set access token
    await loginUser(formValues.email, formValues.password)
      .then((user) => {
        if (user.error) {
          toast.error(user.message);
        } else {
          setUser(user);
          AnalyticsLogin(user);
        }
      })
      .catch((error) => {
        toast.error("Sorry, there was an error logging you in.");
      });
  };

  return (
    <Form
      onSubmit={handleLogin}
      mutators={{
        setValue: ([field, value], state, { changeValue }) => {
          changeValue(state, field, () => value);
        },
      }}
      validate={(values) => {
        const errors = {};
        if (!values.password) {
          errors.password = "Required";
        }
        if (!values.email) {
          errors.email = "Required";
        }
        if (values.email) {
          if (!emailIsValid(values.email)) {
            errors.email = "Please enter a valid email";
          }
        }
        return errors;
      }}
      render={({
        handleSubmit,
        pristine,
        invalid,
        submitting,
        form,
        values,
      }) => (
        <LoginFormFieldSet>
          <legend>Cleancult Login Form</legend>
          <form
            onSubmit={(event) => {
              handleSubmit(event).then((event) => {
                form.mutators.setValue("password", "");
              });
            }}
          >
            <FormCol>
              <Field
                name="email"
                component={PlainTextField}
                htmlType="email"
                label="Email"
                required={true}
                autoComplete="email"
                hideErrorText={true}
              />
            </FormCol>
            <EmailCheck
              email={values ? values.email : ""}
              changeToSuggestedEmail={form.mutators.setValue}
              fieldToChange={"email"}
            />
            <FormCol>
              <Field
                name="password"
                component={PlainTextField}
                htmlType="password"
                label="Password"
                autoComplete="password"
                required={true}
                hideErrorText={true}
              />
            </FormCol>
            <BasicLink href="/password/reset">Forgot Password?</BasicLink>
            <Button
              type="submit"
              loading={submitting}
              modifiers={
                pristine || invalid ? ["disabled", "block"] : ["block"]
              }
            >
              Sign In
            </Button>
          </form>
        </LoginFormFieldSet>
      )}
    />
  );
}

export default LoginForm;
